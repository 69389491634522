@import "styles/variables.scss";

.productCard {
  padding: 5px;
  
  .content{
    border: 1px solid $grey-border-color;
    border-radius: $general-radius;
    transition: all 0.3s;
    padding-top: 15px;

    .imgWrapper{
      position: relative;
      img{
        max-width: 100%;
        width: 100%;
      }
    }

    .details{
      padding: 15px;
      .brand{
        display: inline-block;
        padding: 3px 10px;
        background-color: $brand-color;
        color: white;
        font-size: 0.7em;
      }
      .name{
        // font-weight: bold;
        // font-weight: 300;
        font-size: 1em;
        height: 50px;
        overflow: hidden;
        margin-top: 5px;
      }
      .availability{
        margin-bottom: 5px;
        height: 25px;
        overflow-y: auto;
        .available{
          color: $available-color;
        }
        .unavailable{
          color: $unavailable-color;
        }
      }
    }

    .bottom{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-top: 1px solid $grey-border-color;
      padding-top: 5px;
      .price{
        font-size: 18px;
        font-weight: 700;
        span[data-property="priceWithoutVat"]{
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
          &:after{
            content: " bez DPH";
          }
        }
      }
      button{
        display: none;
        @media (min-width: $sm){
          display: block;
        }
      }
    }

  }

  &:hover{
    .content{
      border-color: $primary-color;
      box-shadow: $general-shadow;
    }
  }
  
}
