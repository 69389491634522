@import "styles/variables.scss";

.labels {
  position: absolute;
  top: 10px;
  left: -10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .label {
    border-radius: 0px;
    min-height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    padding: 0 10px;
    text-align: center;
    display: inline-block;
    font-size: 0.8em;
    color: white;
    background-color: #aaa;
    &.status {
      background-color: $status-color;
    }
    &.new {
      background-color: $new-color;
    }
    &.favourite {
      background-color: $favourite-color;
    }
    &.discount {
      background-color: $discount-color;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      line-height: 1.2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      .title{
        font-size: 0.7em;
        margin-top: -8px;
      }
      .discount{
        font-size: 1.2em;
        font-weight: bold;
        white-space: nowrap;
      } 
      .originPrice {
        text-decoration: line-through;
      }
    }
    &.gift {
      background-color: $gift-color;
    }
  }
}
