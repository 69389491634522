@import "styles/variables";

.wrapper {
  box-sizing: border-box;
}

.homepage-banners-section{
  @media (max-width: $sm) {
    padding: 0;
  }  
  .homepage-banner{
    display: block;
    @media (min-width: $sm) {
      padding: 10px;
    }
    picture, img{
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
      border: 1px solid transparent;
      border-radius: $general-radius;
      &:hover {
        border: 1px solid $primary-color;
      }
    }
  }
}

@import "pages/index/index.page.module";